import React from 'react';
import Home from './Home';
import request from '../../service/request';
import { TRADEHOUSE_POST_SEARCH, RENTHOUSE_SEARCH, NEWHOUSE_SEARCH, BAOZUFANG_SEARCH,YOUFANGFUWU_GET_LIST,OLDNEWHOUSELIST,POLICYDISPLAY_POST_LIST} from '../../service/api';

async function action ({cookie , fetch}) {
    const [subsidy,trade, rent, newHouse, baozufang,oldNew,trackList] = await Promise.all([
         request.POST({
            url: YOUFANGFUWU_GET_LIST,
             params: {
                newHouseStatus:0,
                page: 1,
                size: 3,
                type: 1
            }
        }),
        request.POST({
            url: TRADEHOUSE_POST_SEARCH,
            params: {
                // isRecommend: true,
            },
            headers: cookie ? {
                cityCode: cookie['cityCode'] || 420200
            } : {}
        }),
        request.POST({
            url: RENTHOUSE_SEARCH,
            params: {
                isRecommend: true
            },
            headers: cookie ? {
                cityCode: cookie['cityCode'] || 420200
            } : {}
        }),
        request.POST({
            url: NEWHOUSE_SEARCH,
            params: {
                isRecommend: true
            },
            headers: cookie ? {
                cityCode: cookie['cityCode'] || 420200
            } : {}
        }),
        request.POST({
            url: BAOZUFANG_SEARCH,
            params: {
                page: 1,
                size: 3
            },
            headers: cookie ? {
                cityCode: cookie['cityCode'] || 420200
            } : {}
        }),
        request.POST({
            url: OLDNEWHOUSELIST,
            params: {
                page: 1,
                size: 3
            },
            headers: cookie ? {
                cityCode: cookie['cityCode'] || 420200
            } : {}
        }),
        request.POST({
            url: POLICYDISPLAY_POST_LIST,
            params: {
                page: 1,
                size: 6,
                category: 0,
                orderByType:2,
            },
            headers: cookie ? {
                cityCode: cookie['cityCode'] || 420200
            } : {}
        })
    ]);

    const houseList = {
        trade: {
            name: '二手房',
            priceCompany: '万',
            moreLink: '/ershoufang/list',
            detailLink: '/ershoufang/detail',
            loading: !trade.data,
            code: trade.code,
            list: trade.data ? trade.data : []
        },
        rent: {
            name: '租房',
            priceCompany: '元/月',
            moreLink: '/zufang/list',
            detailLink: '/zufang/detail',
            loading: !rent.data,
            code: rent.code,
            list: rent.data ? rent.data.data : []
        },
        newHouse: {
            name: '新房',
            priceCompany: '元/m²',
            moreLink: '/xinfang/list',
            detailLink: '/xinfang/detail',
            loading: !newHouse.data,
            code: newHouse.code,
            list: newHouse.data ? newHouse.data.data : []
        },
        // baozufang: {
        //     name: '保租房',
        //     priceCompany: '元/月',
        //     moreLink: '/baozf/list',
        //     detailLink: '/baozf/detail',
        //     loading: !baozufang.data,
        //     code: baozufang.code,
        //     list: baozufang.data ? baozufang.data.data : []
        // }
    };
    baozufang.data.data.forEach(item => {
        item.src = item.estateHeaderUrl;
        item.newHouseName = item.estateName;
        item.newHouseAddress = item.address;
        item.areaStart = item.minArea;
        item.areaEnd = item.maxArea;
        item.salePrice = item.maxPrice
    })
    oldNew.data.data.forEach(item => {
        item.src = item.newhouseFirstImg;
    })
    const tabbarList = [
        { name: '保租房',list: baozufang.data ? baozufang.data.data : [],moreUrl:'/baozf/list',detailUrl:'/baozf/detail',key:'id'},
        { name:'优房服务',list: subsidy.data ? subsidy.data.data : [],moreUrl:'/subsidy/list',detailUrl:'/xinfang/detail',key:'newHouseId'},
        { name:'以旧换新',list: oldNew.data ? oldNew.data.data : [],moreUrl:'/tradeIn',detailUrl:'/xinfang/detail',key:'newHouseId'},   
    ]
    
    const fastLinkList = [
        {
            name: '金融服务',
            icon: require("../../images/common/icon-tab-jr.png"),
            link:'/financialService'
        },
        {
            name: '适老改造',
            icon: require("../../images/common/icon-tab-gz.png"),
            link: '/elderlyRenovation'
            
        },
        {
            name: '精商优选',
            icon: require("../../images/common/icon-tab-jz.png"),
            link:'/decorationProcurement'
        },
    ]
    return {
        title: '东楚优房',
        chunks: ['home'],
        keywords: '东楚优房',
        description: '东楚优房',
        component: (
            <Home pagedata={houseList} pageTabbarData={tabbarList} fastLinkList={fastLinkList} trackList={trackList.data.data}  />
        )
    };
}
export default action;
