import useStyles from 'isomorphic-style-loader/useStyles';
import React from 'react';
import s from './index.less';

export default function Nothing(props) {
    useStyles(s);
    // console.log(props.pagetype, 'props.pagetype');
    return (
        (props.pagetype === 404 || props.pagetype === -1) ?
            <div className="nothing-content four-zero-four">
                <img alt="" style={{ width: '350px' }} src={require("../../images/common/404-error.png")} />
                <p>404系统异常，暂无获取数据</p>
            </div> :
            (props.pagetype === 'focus' ?
                <div className="nothing-content">
                    <img alt="" style={{ width: '350px' }} src={require("../../images/common/focus-nothing.png")} />
                    <p>还没有关注任何房源哦 ~</p>
                    <p className="btn" onClick={() => {
                        window.location.href = "/";
                        // return false;
                    }}>去首页看看</p>
                </div>
                :
                <div className="nothing-content">
                    {
                        props.pagetype === 'list' ?
                            <img alt="" style={{ width: '175px' }} src={require("../../images/common/list-nothing.png")} /> :
                            <img alt="" style={{ width: '175px' }} src="https://dcyf.oss-cn-wuhan-lr.aliyuncs.com/client/img/1721914509614.png" />}
                    <p>{
                        props.pagetype === 'list' ? '抱歉，没有找到符合条件的房源' : '暂时还没有数据哦～'}</p>
                </div>
            )


    );
}